import $ from 'jquery';
import { modal } from 'lib/popsss/modal';
import progress, {
  DeferredResultsParams,
  ProgressCallbacks,
  ProgressData,
} from './popsss/progress';

declare global {
  let app_root: string;
  const controller_root: string;
  const __DEV__: boolean;
}

type AlertCallback<AlertData> = (obj: AlertData) => void;

export interface IPOPSSSAjaxDeferred<ResponsePayload, Alert = any>
  extends JQuery.Deferred<ResponsePayload, Alert> {
  alert(
    callback: AlertCallback<Alert>,
  ): IPOPSSSAjaxDeferred<ResponsePayload, Alert>;
  abort(): IPOPSSSAjaxDeferred<ResponsePayload, Alert>;
  jqXHR: JQueryXHR;
}

type POPSSSView = { [key: string]: unknown };
const views: { [key: string]: POPSSSView } = {};

// Offline state
let offlineState = false;
let offlineCapable = false;

// Handle response status 599 from backend after session timeout
function loginRequiredCheck(jqxhr: JQueryXHR) {
  if (jqxhr.status === 599) {
    let url = jqxhr.getResponseHeader('LoginURL');
    if (url) {
      if (url.search('/login') > -1) {
        url =
          url +
          '?came_from=' +
          escape(window.location.pathname + window.location.search);
      }
      popsss.follow(app_root + url);
    } else {
      popsss.follow(app_root);
    }
  }
}

type AlertClass = 'info' | 'danger' | 'success' | 'warning';

type AjaxData = JQuery.Ajax.AjaxSettingsBase<unknown>['data'];

export interface AjaxParams extends JQuery.AjaxSettings {
  // JQuery fileupload plugin allows content type to be false
  contentType?: JQuery.AjaxSettings['contentType'] | false;
}

const popsss = {
  /**
   * Module management
   */

  registerView: function (name: string, module: POPSSSView) {
    let i;
    let ob = views;
    const elements = name.split('.');
    for (i = 0; i < elements.length - 1; i++) {
      if (!Object.hasOwnProperty.call(ob, elements[i])) {
        ob[elements[i]] = {};
      }
      // @ts-ignore
      ob = ob[elements[i]];
    }
    if (Object.hasOwnProperty.call(ob, elements[i])) {
      const old_module = ob[elements[i]];
      for (const prop in old_module) {
        if (Object.hasOwnProperty.call(old_module, prop)) {
          module[prop] = old_module[prop];
        }
      }
    }
    ob[elements[i]] = module;
    return module;
  },

  /**
   * Global support functions
   */

  isOffline: function () {
    return offlineState;
  },
  toggleOffline: function (state: boolean) {
    if (offlineState == state) {
      return;
    }

    offlineState = state;

    $('#header-navbar .disable-offline').each(function () {
      const $li = $(this);
      const $a = $li.children('a');

      $li.toggleClass('disabled', offlineState);

      if (offlineState) {
        $a.data('href-disabled', $a.attr('href'));
        $a.removeAttr('href');
      } else {
        if ($a.data('href-disabled')) {
          $a.attr('href', $a.data('href-disabled'));
        }
      }
    });
  },
  enableOfflineCapability: function () {
    offlineCapable = true;
  },
  /**
   * Handle the Celery task results and show the progres.
   * @param {Object} data - The data of the request.
   * @param {function} done_callback - The callback to be called then progress is finished.
   * @param {function} fail_callback - The callback to be called then request has failed. It
   * takes the data.error as parameter.
   */
  show_progress: function (
    data: { __progress: ProgressData; error?: string },
    done_callback: () => void,
    fail_callback: (error: string) => void,
  ) {
    if (data && data.__progress) {
      const progress_deferred = $.Deferred();
      const currentProgress = new popsss.progress.Progress(
        data.__progress,
        progress_deferred,
      );

      progress_deferred.done(function () {
        done_callback();
      });

      progress_deferred.fail(function (failData) {
        if (!currentProgress.cancelled) {
          fail_callback(failData.error);
        }
      });

      currentProgress.start();
    } else if (data.error) {
      fail_callback(data.error);
    } else {
      done_callback();
    }
  },
  url: function (uri: string) {
    if (uri.slice(0, 4) === 'http') {
      return uri;
    }
    if (uri.slice(0, 2) === './') {
      uri = uri.replace('.', controller_root);
    }
    return app_root + uri;
  },
  params: function (url: string) {
    /* Extract parameters from an URI so they can be used in a form. */
    const query: { [key: string]: string } = {};
    const param_string = url.split('?')[1];
    let params;

    if (param_string) {
      params = param_string.split('&');
      for (const item of params) {
        const b = item.split('=');
        // the replace is needed as decodeURIComponent doesn't "unquote" the space/plus change
        query[decodeURIComponent(b[0])] = decodeURIComponent(
          b[1].replace(/\+/g, ' ') || '',
        );
      }
    }
    return query;
  },
  ajax: function <T extends DeferredResultsParams, DeferredFail = any>(
    data: AjaxParams,
    progressCallbacks?: ProgressCallbacks<T>,
    callerHandlesErrors?: boolean,
  ): IPOPSSSAjaxDeferred<T, DeferredFail> {
    const jqxhr = $.ajax(data);
    const deferred = $.Deferred();

    jqxhr.fail(function (j, t, e) {
      loginRequiredCheck(j);
      if (!callerHandlesErrors) {
        const obj = popsss.alertFromRequest(j, t, e);
        deferred.reject(j, t, e, obj);
      }
    });

    jqxhr.done(function (doneData) {
      popsss.toggleOffline(false);

      if (doneData && doneData.__progress) {
        const progress_deferred = $.Deferred();
        const currentProgress = new popsss.progress.Progress(
          doneData.__progress,
          progress_deferred,
          progressCallbacks,
        );

        progress_deferred.done(function (progressData) {
          deferred.resolve(progressData || {});
        });

        progress_deferred.fail(function (failData) {
          if (currentProgress.cancelled) {
            deferred.reject(jqxhr, 'abort', 'Abort');
          } else {
            deferred.reject(failData || {});
          }
        });

        currentProgress.start();
      } else {
        deferred.resolve(doneData);
      }
    });

    // @ts-ignore
    deferred.jqXHR = jqxhr;
    // @ts-ignore
    deferred.abort = function () {
      jqxhr.abort();
    };
    // @ts-ignore
    deferred.alert = function (callback) {
      deferred.fail(function (j, t, e, obj) {
        if (obj) {
          callback(obj);
        }
      });
      return deferred;
    };

    return deferred as IPOPSSSAjaxDeferred<T, DeferredFail>;
  },
  get: function <T extends DeferredResultsParams, DeferredFail = any>(
    uri: string,
    data?: AjaxData,
    dataType?: string,
  ) {
    return popsss.ajax<T, DeferredFail>({
      type: 'GET',
      url: popsss.url(uri),
      data: data,
      dataType: dataType,
    });
  },
  post: function <T extends DeferredResultsParams, DeferredFail = any>(
    uri: string,
    data?: AjaxData,
    dataType?: string,
    contentType?: string,
    progress_handler?: ProgressCallbacks<T>,
  ) {
    return popsss.ajax<T, DeferredFail>(
      {
        type: 'POST',
        url: popsss.url(uri),
        data: data,
        dataType: dataType,
        contentType,
      },
      progress_handler,
    );
  },
  put: function <T extends DeferredResultsParams, DeferredFail = any>(
    uri: string,
    data?: AjaxData,
    dataType?: string,
  ) {
    return popsss.ajax<T, DeferredFail>({
      type: 'PUT',
      url: popsss.url(uri),
      data: data,
      dataType: dataType,
    });
  },
  delete: function <T extends DeferredResultsParams, DeferredFail = any>(
    uri: string,
    data?: AjaxData,
    dataType?: string,
  ) {
    return popsss.ajax<T, DeferredFail>({
      type: 'DELETE',
      url: popsss.url(uri),
      data: data,
      dataType: dataType,
    });
  },
  follow: function (uri: string) {
    // @ts-ignore
    return (window.location = popsss.url(uri));
  },
  getJSON: function <T extends DeferredResultsParams, Alert = any>(
    uri: string,
    data?: AjaxData,
  ) {
    return popsss.get<T, Alert>(uri, data, 'json');
  },
  postFormData: function <T extends DeferredResultsParams, Alert = any>(
    uri: string,
    data?: AjaxData,
    progressHandler?: ProgressCallbacks<T>,
  ) {
    return popsss.post<T, Alert>(
      uri,
      data,
      'json',
      'application/x-www-form-urlencoded; charset=UTF-8',
      progressHandler,
    );
  },
  postJSON: function <T extends DeferredResultsParams, Alert = any>(
    uri: string,
    data?: AjaxData,
    progressHandler?: ProgressCallbacks<T>,
  ) {
    return popsss.post<T, Alert>(
      uri,
      JSON.stringify(data),
      'json',
      'application/json',
      progressHandler,
    );
  },
  putJSON: function <T extends DeferredResultsParams, Alert = any>(
    uri: string,
    data?: AjaxData,
  ) {
    return popsss.put<T, Alert>(uri, data, 'json');
  },
  deleteJSON: function <T extends DeferredResultsParams, Alert = any>(
    uri: string,
    data?: AjaxData,
  ) {
    return popsss.delete<T, Alert>(uri, data, 'json');
  },

  // Please use downloadFileWithLink before we get rid of this function.
  downloadFile: function (url: string, data?: AjaxData, method = 'get') {
    const form = $('<form />', {
      method: method,
      action: popsss.url(url),
    });
    const all_data = popsss.params(url);
    // @ts-ignore Assumes AjaxData is an object, however JQuery accepts a string also
    for (const item in data) {
      // @ts-ignore Assumes AjaxData is an object, however JQuery accepts a string also
      all_data[item] = data[item];
    }

    if (method.toLowerCase() === 'post') {
      all_data['_csrf_token'] = window.__CONFIG__._csrf_token;
    }

    for (const prop in all_data) {
      $('<input />', {
        type: 'hidden',
        name: prop,
        value: all_data[prop],
      }).appendTo(form);
    }

    form.appendTo('body').submit();
  },

  // Preferred way for downloading file. Instead of creating a form and manipulating the parameters
  // as downloadFile does, this one is just using an anchor with the HTML5 "download" feature.
  downloadFileWithLink: function (url: string, filename = '') {
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.display = 'none';
    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
  },

  // TODO: Turn this in to a jQuery plugin/extension
  inputChangeOnEnter: function (inputs: JQuery) {
    inputs.each(function () {
      $(this).data('original-value', $(this).val());
    });

    inputs.on('keydown', function (e) {
      const key = e.charCode ? e.charCode : e.keyCode ? e.keyCode : 0;
      if (key === 13) {
        e.preventDefault();
        $(this).trigger('change');
      } else if (key === 27) {
        e.preventDefault();
        $(this).val($(this).data('original-value'));
        $(this).trigger('change');
      }
    });

    return inputs;
  },

  /**
   * Alerts and error handling
   */

  alert: function (
    message: string,
    cls_?: AlertClass,
    flash?: boolean,
    flashDuration?: number,
  ) {
    const $container = $('#alert-container');
    const cls = cls_ || 'info';
    let icon;

    if (!message) {
      if (__DEV__) {
        /* eslint-disable-next-line no-console */
        console.warn('No message supplied to popsss.alert');
      }
      return;
    }

    if (cls === 'success') {
      icon = 'glyphicon-ok-circle';
    } else if (cls === 'info') {
      icon = 'glyphicon-record';
    } else {
      icon = 'glyphicon-remove-circle';
    }

    const $element = $('<div />', { class: 'alert alert-' + cls })
      .text(message)
      .prepend($('<i />', { class: 'glyphicon ' + icon }))
      .prepend(
        $('<button />', { type: 'button', class: 'close', html: '&times;' }),
      );

    function alertFade() {
      $element.fadeOut('normal', function () {
        $element.remove();
      });
      clearTimeout(timerId);
    }

    // Clicking anywhere on alert will dismiss
    let timeOut;
    if (flash) {
      if (flashDuration) {
        timeOut = flashDuration;
      } else {
        timeOut = 5000;
      }
    } else {
      timeOut = 60000;
    }
    $element.click(alertFade);
    const timerId = setTimeout(alertFade, timeOut);

    // Show maximum of 5 alerts at a time
    while ($container.children().length > 4) {
      $container.children(':first').remove();
    }

    $container.append($element.hide());
    $element.slideDown();

    return $element;
  },
  alertError: function (message: string) {
    return popsss.alert(message, 'danger');
  },
  alertWarning: function (message: string) {
    return popsss.alert(message, 'warning');
  },
  alertSuccess: function (message: string) {
    return popsss.alert(message, 'success');
  },
  flash: function (message: string, status: AlertClass = 'info') {
    return popsss.alert(message, status, true);
  },
  flashInfo: function (message: string, duration?: number) {
    return popsss.alert(message, 'info', true, duration);
  },
  flashError: function (message: string, duration?: number) {
    return popsss.alert(message, 'danger', true, duration);
  },
  flashWarning: function (message: string, duration?: number) {
    return popsss.alert(message, 'warning', true, duration);
  },
  flashSuccess: function (message: string, duration?: number) {
    return popsss.alert(message, 'success', true, duration);
  },

  alertFromRequest: function (
    jqXHR: JQueryXHR,
    textStatus: string,
    errorThrown?: string,
  ) {
    if (textStatus === 'abort' || errorThrown === 'abort') {
      return null;
    }

    // Deal with connection timeout separately
    if (
      jqXHR.status === 0 ||
      textStatus === 'timeout' ||
      (textStatus == 'parsererror' &&
        jqXHR.responseText.search("We'll be right back") > 0)
    ) {
      if (offlineCapable) {
        return null;
      }

      return popsss.flashWarning(
        '<p><b>Connection lost or timed out</b></p>' +
          '<p>Could not establish a connection, are you still ' +
          'connected to the internet?</p>',
      );
    }

    let message =
      'Oops! An error occurred. If you think this is wrong,' +
      ' please let us know.';

    if (jqXHR.status === 500) {
      if (__DEV__) {
        // Detect Backlash interactive debugger and inject into an iframe
        if (jqXHR.responseText.search('Backlash') > 0) {
          popsss.modal
            .dialog(
              'Debugger',
              // @ts-ignore
              $('<iframe />', {
                srcdoc: jqXHR.responseText,
              })
                .css('width', '100%')
                .css('height', '600px'),
            )
            .find('.modal-dialog')
            .css('width', '100%');
          return;
        }
      }

      message =
        'Oops! An error occurred. All internal server errors ' +
        'are automatically reported to us and we will try to ' +
        'resolve them as soon as possible.';
    }

    if (jqXHR.responseJSON && jqXHR.responseJSON.error) {
      return popsss.alertError(jqXHR.responseJSON.error);
    }

    // Errors from webob exceptions
    if (jqXHR.responseJSON && jqXHR.responseJSON.message) {
      return popsss.alertError(jqXHR.responseJSON.message);
    }

    if (jqXHR.responseJSON && jqXHR.responseJSON.warning) {
      return popsss.alertWarning(jqXHR.responseJSON.warning);
    }

    let errorMessage = errorThrown;
    if (!errorMessage) {
      if (jqXHR.statusText) {
        errorMessage = jqXHR.statusText;
      } else {
        errorMessage = jqXHR.responseText;
      }
    }

    return popsss.alertError(`${errorMessage}: ${message}`);
  },

  /**
   * User interface functions
   */

  // Called from master template
  dismissBroadcast: function (btn: HTMLButtonElement, recipient_id: number) {
    popsss
      .postFormData('/messages/mark_read', {
        ids: JSON.stringify([recipient_id]),
      })
      .done(function () {
        $(btn)
          .closest('.broadcast-message')
          .fadeOut('slow', function () {
            $(this).remove();
          });
      });
  },

  impersonateUser: function (userIdentifier: string, redirectUrl?: string) {
    popsss
      .postFormData('/sites/switch_user', { user_identifier: userIdentifier })
      .done((result: { redirect_url: string }) => {
        popsss.follow(redirectUrl ?? result.redirect_url ?? '#');
      });
  },

  modal: modal,
  progress: progress,
};

// @ts-ignore
window.views = views;

// Global symbols required for code that has not yet been converted to AMD
// @ts-ignore
window.input_change_on_enter = popsss.inputChangeOnEnter;

// eslint-disable-next-line import/no-default-export -- FIXME: Convert to a named export https://github.com/wegotpop/popsss/wiki/Imports#default-exports
export default popsss;
