import popsss from 'lib/popsss';
import $ from 'jquery';

interface InboxResult {
  unread: boolean;
  unresponded: boolean;
}

export function refreshInboxBadges() {
  popsss.getJSON('/api/account/v1/inbox_counts').then((result: InboxResult) => {
    if (result.unread) {
      $('#navbar-inbox > .badge-unread').show();
    } else {
      $('#navbar-inbox > .badge-unread').hide();
    }
    if (result.unresponded) {
      $('#navbar-inbox > .badge-unresponded').show();
    } else {
      $('#navbar-inbox > .badge-unresponded').hide();
    }
  });
}
