import 'pusher-js';

const pusher = {};

let pusherConfig = {};
const userChannel = {};
const userBinds = {};

export function bindChannelEvent(channelName, event, func) {
  if (!pusher[channelName]) {
    pusher[channelName] = new window.Pusher(pusherConfig.key, {
      authEndpoint: '/api/events/v1/pusher_auth',
      cluster: pusherConfig.cluster,
      encrypted: true,
    });

    pusher[channelName].subscribe(channelName);
  }

  pusher[channelName].bind(event, func);
  return pusher[channelName];
}

export function unbindChannelEvent(channelName, event) {
  if (pusher[channelName]) {
    pusher[channelName].unbind(event);
  }
}

export function subscribePusher(channelName) {
  return new Promise((resolve, reject) => {
    if (!pusherConfig) {
      reject(Error('No pusher configuration'));
    }

    if (pusher[pusherConfig.channel]) {
      resolve(pusher[pusherConfig.channel].subscribe(channelName));
      return;
    }

    pusher[pusherConfig.channel] = new window.Pusher(pusherConfig.key, {
      authEndpoint: '/api/events/v1/pusher_auth',
      cluster: pusherConfig.cluster,
      encrypted: true,
    });
    resolve(pusher[pusherConfig.channel].subscribe(channelName));
  });
}

export function configurePusher(config) {
  pusherConfig = config;
  return new Promise((resolve) => {
    subscribePusher(pusherConfig.channel).then(
      (channel) => {
        userChannel[pusherConfig.channel] = channel;
        if (userBinds[pusherConfig.channel]) {
          userBinds[pusherConfig.channel].forEach((bindConfig) => {
            userChannel[pusherConfig.channel].bind(
              bindConfig.event,
              bindConfig.callback,
            );
          });
        }
        resolve();
      },
      () => {
        resolve();
      },
    );
  });
}

export function setPusherChannel(pusherChannel) {
  const dataConfig = window.__CONFIG__;
  const newPusherConfig = dataConfig.pusher;
  newPusherConfig.channel = pusherChannel;
  configurePusher(newPusherConfig);
}

export function addUserBind(event, callback) {
  const windowPusherConfig = window.__CONFIG__.pusher;

  if (userChannel[windowPusherConfig.channel]) {
    userChannel[windowPusherConfig.channel].bind(event, callback);
  } else {
    if (!userBinds[windowPusherConfig.channel]) {
      userBinds[windowPusherConfig.channel] = [];
    }
    userBinds[windowPusherConfig.channel].push({ event, callback });
  }
}

export function removeBind(event) {
  const windowPusherConfig = window.__CONFIG__.pusher;

  if (userChannel[windowPusherConfig.channel]) {
    userChannel[windowPusherConfig.channel].unbind(event);
  }
}

/**
 * Application level default bindings
 */

// Update inbox counter badge
addUserBind('inbox_count', (data) => {
  const inbox = $('#navbar-inbox');
  if (inbox.length === 0) {
    return;
  }

  if (data.unread > 0) {
    inbox.find('.badge-unread').val('<i class="fas fa-envelope"></i>').show();
  } else {
    inbox.find('.badge-unread').hide();
  }

  if (data.unresponded > 0) {
    inbox
      .find('.badge-unresponded')
      .val('<i class="fas fa-envelope-open"></i>')
      .show();
  } else {
    inbox.find('.badge-unresponded').hide();
  }

  document.title = $('head>title').data('title');
});

// Flash new message
addUserBind('message', (data) => {
  popsss.alert(`New message received: ${data.summary}`);
});

addUserBind('flash', (data) => {
  popsss.flash(data.message);
});

// Logout event
addUserBind('logout', () => {
  setTimeout(() => {
    window.location.reload();
  }, 3000);
});

// eslint-disable-next-line import/no-default-export -- FIXME: Convert to a named export https://github.com/wegotpop/popsss/wiki/Imports#default-exports
export default {
  addUserBind,
  removeBind,
  configurePusher,
  subscribe: subscribePusher,
  setPusherChannel,
};
